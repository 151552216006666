// export const sidebarData = [
//   {
//     name: "SOLICITAR COTAÇÃO",
//     routepath: "/order-quote",
//   },
//   {
//     type: "heading",
//     name: "Projetos"
//   },
//   {
//     name: "Projeto Demo",
//     iconClass: "fas fa-expand",
//     child: [
//       {
//         listname: "27-09-2023",
//         iconClass: "fas fa-plus",
//         child: [
//           {
//             listname: "Gêmeos Digitais",
//             routepath: "/gemeo-digital",
//             shortname: "-"
//           }
//         ]
//       },
//       {
//         listname: "05-10-2023",
//         iconClass: "fas fa-plus",
//         child: [
//           {
//             listname: "Tour Virtual",
//             routepath: "/tour",
//             shortname: "-"
//           },
//           {
//             listname: "Cartografia",
//             routepath: "/cartografia",
//             shortname: "-"
//           }
//         ]
//       },
//       {
//         listname: "18-10-2023",
//         iconClass: "fas fa-plus",
//         child: [
//           {
//             listname: "Tour Virtual",
//             routepath: "/tour2/2",
//             shortname: "-"
//           }
//         ]
//       }
//     ]
//   },
//   {
//     type: "heading",
//     name: "sidebar.user"
//   },
//   {
//     name: "sidebar.userprofile",
//     routepath: "/perfil",
//     iconClass: "far fa-user"
//   },
//   {
//     type: "heading",
//     name: "sidebar.admin"
//   },
//   {
//     name: "Gerenciar Projetos",
//     routepath: "/workflow",
//     iconClass: "fas fa-clipboard-list"
//   },
//   {
//     type: "heading",
//     name: "Ambiente de Testes"
//   },
//   {
//     name: "Listar Buckets",
//     routepath: "/bucketList"
//   },
//   {
//     name: "Formulário Contato",
//     routepath: "/form-mail"
//   },
//   {
//     name: "Carregar Arquivos",
//     routepath: "/upload-files"
//   }
// ];

export const sidebarData = [
  {
    name: "Dashboard",
    routepath: "/dashboard",
    iconClass: "fas fa-chart-pie"
  },
  {
    name: "Projetos",
    routepath: "/projects",
    iconClass: "fas fa-folder"
  },
  {
    name: "Relatórios",
    routepath: "/reports",
    iconClass: "fas fa-file-alt"
  },
  {
    name: "Uploads",
    routepath: "/uploads",
    iconClass: "fas fa-cloud-upload-alt"
  },
  // {
  //   name: "Downloads",
  //   routepath: "/downloads",
  //   iconClass: "fas fa-download"
  // },
  // {
  //   name: "Gêmeos Digitais",
  //   routepath: "/gemeo-digital",
  //   iconClass: "fas fa-cubes"
  // },
  // {
  //   name: "Tour Virtual",
  //   routepath: "/tour"
  // },
  // {
  //   name: "Formulário Contato",
  //   routepath: "/form-mail"
  // },
  // {
  //   name: "Carregar Arquivos",
  //   routepath: "/upload-files"
  // },
];
